import { configureStore } from "@reduxjs/toolkit";
import { reducers as signupUserReducer } from "../redux/slice/docsslice";
import { reducers as tableReducer } from "../redux/slice/docstableslice";
// import taskReducers from "../redux/slice/taskslice"
import fcmreducer from "../redux/slice/fcmtokenslice"
import {notifydataReducer, recentSearchReducer} from "../redux/slice/notifydataslice"


const store = configureStore({
  reducer: {
    // loginuser: loginReducer,
    signupUser: signupUserReducer.signupUser,
    verifyOtp: signupUserReducer.verifyOtp,
    userLogin: signupUserReducer.userLogin,
    accountCreation: signupUserReducer.accountCreation,
    UserForgotPassword: signupUserReducer.UserForgotPassword,
    UserResetPassword: signupUserReducer.UserResetPassword,
    tableFieldExtraction: tableReducer.tableFieldExtraction,
    recentSearch: tableReducer.recentSearch,
    accountListInfo: tableReducer.accountListInfo,
    dynamicTableCreation: tableReducer.dynamicTableCreation,
    tableData: tableReducer.tableData,
    tableDelete: tableReducer.tableDelete,
    tableAccountData: tableReducer.tableAccountData,
    tableUpdateInfo: tableReducer.tableUpdateInfo,
    reportFileCreation: tableReducer.reportFileCreation,
    reportServiceData: tableReducer.reportServiceData,
    remittanceInformation: tableReducer.remittanceInformation,
    localFileExtraction: tableReducer.localFileExtraction,
    fcmToken: fcmreducer,
    notifydata: notifydataReducer,
    recentSearchData: recentSearchReducer,
    NotificationsList: tableReducer.NotificationsList,
    FcmTokenUpdate: tableReducer.FcmTokenUpdate,
    NotificationsReadByUser: tableReducer.NotificationsReadByUser,
    NotificationsDeleteByUser: tableReducer.NotificationsDeleteByUser,
    reportQueryDelete: tableReducer.reportQueryDelete,
    migrateRelationalDb: tableReducer.migrateRelationalDb,
    migrateNonRelationalDb: tableReducer.migrateNonRelationalDb,
    migratePineconeDb: tableReducer.migratePineconeDb,
    mappingList: tableReducer.mappingList,
    updateMapping: tableReducer.updateMapping,

    // tasks: taskReducers
  },
});

export default store;