import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { useDispatch } from 'react-redux';
import { setToken } from '../../redux/slice/fcmtokenslice';



// const firebaseConfig = {
//   apiKey: "AIzaSyB0mHOgtfxxwHb-UsbzraLONtjeqYw8oGM",
//   authDomain: "vivyadocs-acd06.firebaseapp.com",
//   projectId: "vivyadocs-acd06",
//   storageBucket: "vivyadocs-acd06.appspot.com",
//   messagingSenderId: "603883693204",
//   appId: "1:603883693204:web:3509502b9830449f4f0081",
//   measurementId: "G-MDP2ZT0P1M"
// };

const firebaseConfig = {
  apiKey: "AIzaSyCMnSQ7ucbqAEiF_SpH8FkE4VPmasfhL3I",
  authDomain: "vivyadocs-e6a32.firebaseapp.com",
  projectId: "vivyadocs-e6a32",
  storageBucket: "vivyadocs-e6a32.appspot.com",
  messagingSenderId: "395146517753",
  appId: "1:395146517753:web:e2e7fc0631ea03ed9a9628",
  measurementId: "G-Z5R0VF6825"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();


export const RequestForToken = async () => {
  const dispatch = useDispatch();

  try {
   // const currentToken = await getToken(messaging, { vapidKey: 'BJMsGFWGeB9RY5utDc1LIFG20u5797CiyLOQ_jvRXPOo1COriCycwyBIGZiAo09n555s7KT8VO46_k0vfLTNpXU' });

    const currentToken = await getToken(messaging, { vapidKey: 'BNYHDrWNiaaOHVN5YIgvbOJJQvAScSIeJ4f8_TyltowDMEqR9bh3-vl8KzYLG4I49GEwTezcVtnvCFBUpozQF50' });

    if (currentToken) {
      console.log('Current token for client: ', currentToken);
      // Perform any other necessary action with the token
      dispatch(setToken(currentToken));
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};

  export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });