import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setRecentSearchData, setnotifydata } from '../redux/slice/notifydataslice';
import { baseUrl } from '../constants/baseurl';
export const apiCall = (apiRequest) => {
  console.log("apiCall",apiRequest)
  return new Promise((resolve, reject) => {
    axios({
      method: apiRequest.method,
      url: apiRequest.url,
      headers: apiRequest.headers,
      data: apiRequest.data || {},
      // timeout: 70000,
    })
      .then(async (response) => {
        console.log("resp",response)
        resolve(response);
      })
      .catch(async (error) => {
        let response = error.response;
        console.log(error)
        if (response && response.status === 401) {
          console.log(error)
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          window.location.reload();
        }
        reject(error);
      });
  });
};





const getNotificationList = (dispatch) => async () => {
  const user_email = localStorage.getItem('useremail');
  const data = { user_email };

  if (user_email) {
    const notificationList = {
      method: 'post',
      headers: { 'Content-Type': 'application/json', Authorization: 'Token ' + localStorage.getItem('token') },
      url: `${baseUrl}notification/notification_list/`,
      data,
    };

    try {
      // dispatch(showLoading.actions.toggleLoading(true));
      // setNotifyLoading(true)
      const res = await apiCall(notificationList, dispatch);
      console.log('daasdasd::::::::::::::', res.data.data);
      dispatch(setnotifydata(res.data.data));
    } catch (error) {
      // dispatch(showLoading.actions.toggleLoading(false));
      throw error;
    }
  }
};

const getRecentSearch = (dispatch) => async () => {
  const user_email = localStorage.getItem('useremail');
  const data = { user_email };

  if (user_email) {
    const recentSearchList = {
      method: 'post',
      headers: { 'Content-Type': 'application/json', Authorization: 'Token ' + localStorage.getItem('token') },
      url: `${baseUrl}report/report_query_search/`,
      data,
    };

    try {
      // dispatch(showLoading.actions.toggleLoading(true));
      // setNotifyLoading(true)
      const res = await apiCall(recentSearchList, dispatch);
      console.log('daasdasd::::::::::::::', res.data.data);
      dispatch(setRecentSearchData(res.data.data));
    } catch (error) {
      // dispatch(showLoading.actions.toggleLoading(false));
      throw error;
    }
  }
};

export { getNotificationList, getRecentSearch };
