import { createSlice } from "@reduxjs/toolkit";


const notifydataSlice = createSlice({
  name: "notifydata",
  initialState: {
    notifydata: null,
    },
  reducers: {
    setnotifydata: (state,action) => {
      state.notifydata = action.payload;
    }
  }
})

const recentSearchdataSlice = createSlice({
  name: "recentSearchData",
  initialState: {
    recentSearchData: null,
    },
  reducers: {
    setRecentSearchData: (state,action) => {
      state.recentSearchData = action.payload;
    }
  }
})

export const { setnotifydata } = notifydataSlice.actions;
export const { setRecentSearchData } = recentSearchdataSlice.actions;
export const notifydataReducer = notifydataSlice.reducer;
export const recentSearchReducer = recentSearchdataSlice.reducer;
