import { createSlice } from "@reduxjs/toolkit";
import { recentSearch, tableFieldExtraction, accountListInfo, dynamicTableCreation, tableData, tableDelete, tableAccountData, tableUpdateInfo, reportFileCreation, reportServiceData, remittanceInformation, localFileExtraction, NotificationsList, FcmTokenUpdate, NotificationsReadByUser, NotificationsDeleteByUser, reportQueryDelete, migrateRelationalDb, migrateNonRelationalDb, migratePineconeDb, mappingList, updateMapping } from "../actions/docstableaction";


const tableFieldExtractionSlice = createSlice({
    name: 'tableFieldExtraction',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(tableFieldExtraction.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(tableFieldExtraction.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(tableFieldExtraction.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const recentSearchSlice = createSlice({
    name: 'recentSearch',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(recentSearch.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(recentSearch.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(recentSearch.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const accountListInfoSlice = createSlice({
    name: 'accountListInfo',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(accountListInfo.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(accountListInfo.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(accountListInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const dynamicTableCreationSlice = createSlice({
    name: 'dynamicTableCreation',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(dynamicTableCreation.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(dynamicTableCreation.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(dynamicTableCreation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});


const tableAccountDataSlice = createSlice({
    name: 'tableAccountData',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(tableAccountData.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(tableAccountData.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(tableAccountData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const tableDataSlice = createSlice({
    name: 'tableData',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(tableData.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(tableData.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(tableData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const tableDeleteSlice = createSlice({
    name: 'tableDelete',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(tableDelete.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(tableDelete.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(tableDelete.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const tableUpdateInfoSlice = createSlice({
    name: 'tableUpdateInfo',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(tableUpdateInfo.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(tableUpdateInfo.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(tableUpdateInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const reportFileCreationSlice = createSlice({
    name: 'reportFileCreation',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(reportFileCreation.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(reportFileCreation.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(reportFileCreation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const reportServiceDataSlice = createSlice({
    name: 'reportServiceData',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(reportServiceData.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(reportServiceData.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(reportServiceData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const remittanceInformationSlice = createSlice({
    name: 'remittanceInformation',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(remittanceInformation.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(remittanceInformation.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(remittanceInformation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});


const localFileExtractionSlice = createSlice({
    name: 'localFileExtraction',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(localFileExtraction.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(localFileExtraction.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(localFileExtraction.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const NotificationsListSlice = createSlice({
    name: 'NotificationsList',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(NotificationsList.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(NotificationsList.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(NotificationsList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const FcmTokenUpdateSlice = createSlice({
    name: 'FcmTokenUpdate',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(FcmTokenUpdate.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(FcmTokenUpdate.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(FcmTokenUpdate.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const NotificationsReadByUserSlice = createSlice({
    name: 'NotificationsReadByUser',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(NotificationsReadByUser.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(NotificationsReadByUser.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(NotificationsReadByUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});


const NotificationsDeleteByUserSlice = createSlice({
    name: 'NotificationsDeleteByUser',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(NotificationsDeleteByUser.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(NotificationsDeleteByUser.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(NotificationsDeleteByUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});


const ReportDeleteByUserSlice = createSlice({
    name: 'reportQueryDelete',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(reportQueryDelete.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(reportQueryDelete.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(reportQueryDelete.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});


const MigrateRelationalDbSlice = createSlice({
    name: 'migrateRelationalDb',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(migrateRelationalDb.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(migrateRelationalDb.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(migrateRelationalDb.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});


const MigrateNonRelationalDbSlice = createSlice({
    name: 'migrateNonRelationalDb',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(migrateNonRelationalDb.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(migrateNonRelationalDb.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(migrateNonRelationalDb.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const MigratePineconeDbSlice = createSlice({
    name: 'migrateNonRelationalDb',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(migratePineconeDb.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(migratePineconeDb.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(migratePineconeDb.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});


const mappingListSlice = createSlice({
    name: 'mappingList',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(mappingList.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(mappingList.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(mappingList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const updateMappingSlice = createSlice({
    name: 'updateMapping',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateMapping.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(updateMapping.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(updateMapping.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

export const reducers = {
    tableFieldExtraction: tableFieldExtractionSlice.reducer,
    recentSearch: recentSearchSlice.reducer,
    accountListInfo: accountListInfoSlice.reducer,
    dynamicTableCreation: dynamicTableCreationSlice.reducer,
    tableData: tableDataSlice.reducer,
    tableDelete: tableDeleteSlice.reducer,
    tableAccountData: tableAccountDataSlice.reducer,
    tableUpdateInfo: tableUpdateInfoSlice.reducer,
    reportFileCreation: reportFileCreationSlice.reducer,
    reportServiceData: reportServiceDataSlice.reducer,
    remittanceInformation: remittanceInformationSlice.reducer,
    localFileExtraction: localFileExtractionSlice.reducer,
    NotificationsList: NotificationsListSlice.reducer,
    FcmTokenUpdate: FcmTokenUpdateSlice.reducer,
    NotificationsReadByUser: NotificationsReadByUserSlice.reducer,
    NotificationsDeleteByUser: NotificationsDeleteByUserSlice.reducer,
    reportQueryDelete: ReportDeleteByUserSlice.reducer,
    migrateRelationalDb: MigrateRelationalDbSlice.reducer,
    migrateNonRelationalDb: MigrateNonRelationalDbSlice.reducer,
    migratePineconeDb: MigratePineconeDbSlice.reducer,
    mappingList:  mappingListSlice.reducer,
    updateMapping: updateMappingSlice.reducer,
};