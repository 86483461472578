import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from "react-toastify";
import { RequestForToken, onMessageListener } from './firebase';
import getNotificationList from '../../api';
import { useDispatch } from 'react-redux';

const Notifications = () => {
  const dispatch = useDispatch()
  const [notification, setNotification] = useState({ title: '', body: '' });
  const notify = () => toast(<ToastDisplay />);

  // const fetchNotifications = getNotificationList(dispatch)

  // useEffect(()=>{
  //   fetchNotifications()    
  // },[])

  function ToastDisplay() {
    return (
      <div>
        <p><b>{notification?.title}</b></p>
        <p>{notification?.body}</p>
      </div>
    );
  };

  useEffect(() => {
    if (notification?.title) {
      notify()
    }
  }, [notification])

  RequestForToken();

  onMessageListener()
    .then((payload) => {
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch((err) => console.log('failed: ', err));

  return (
    // <ToastContainer />
    <div></div>
  )
}

export default Notifications;