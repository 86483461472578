import { createSlice } from "@reduxjs/toolkit";


const fcmTokenSlice = createSlice({
  name: "fcmToken",
  initialState: {
    fcmToken: null,
    },
  reducers: {
    setToken: (state,action) => {
      state.token = action.payload;
    }
  }
})



export const { setToken } = fcmTokenSlice.actions;
export default fcmTokenSlice.reducer