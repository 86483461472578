import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../constants/baseurl";
import { apiCall } from "../../api/index";
import { showLoading } from "../slice/loadingslice"



const createAccountAsyncThunk = (method, type, contentType, url) => createAsyncThunk(type, async (payload, thunkAPI) => {
    const { data } = payload;
    const { dispatch } = thunkAPI;
    console.log("data:::", data)
    const apiRequest = {
        method: method,
        headers: { 'Content-Type': contentType , Authorization: 'Token ' + localStorage.getItem('token') },
        url: `${baseUrl}${url}`,
        data,
    };

    try {
        dispatch(showLoading.actions.toggleLoading(true));
        const res = await apiCall(apiRequest, dispatch);
        const response = res.data;

        dispatch(showLoading.actions.toggleLoading(false));

        if (response.statusFlag === true) {
            console.log(`login action ------ ${type}`, response);
            return response;
        } else {
            console.error(`account action - ${type}`, response);

            // Check if there is an error code in the response
            const errorCode = response.message;

            // You can throw a custom error with the error code
            throw new Error(errorCode);
        }
    } catch (error) {
        dispatch(showLoading.actions.toggleLoading(false));
        throw error;
    }
});

export const tableFieldExtraction = createAccountAsyncThunk('post', 'tableFieldExtraction','application/json', 'table/table_fields_extraction/');
export const recentSearch = createAccountAsyncThunk('post', 'recentSearch', 'application/json', 'report/report_query_search/');
export const accountListInfo = createAccountAsyncThunk('post', 'accountListInfo', 'application/json', 'accounts_info/accounts_list/');
export const dynamicTableCreation = createAccountAsyncThunk('post', 'dynamicTableCreation', 'application/json', 'table/dynamic_table_creation/');
export const accountDeletion = createAccountAsyncThunk('delete', 'accountDeletion', 'application/json', 'accounts_info/account_delete/');
export const tableData = createAccountAsyncThunk('post','tableData', 'application/json', 'report/data_list/');
export const tableDelete = createAccountAsyncThunk('delete','tableDelete', 'application/json', 'table/delete_table/');
export const tableAccountData = createAccountAsyncThunk('post','tableAccountData', 'application/json', 'table/table_account_list/');
export const tableUpdateInfo = createAccountAsyncThunk('put','tableUpdateInfo', 'application/json', 'table/table_info_update/');
export const reportFileCreation = createAccountAsyncThunk('post','reportFileCreation', 'application/json','report/report_file_creation/')
export const reportServiceData = createAccountAsyncThunk('post','reportServiceData', 'application/json','report/report_service/')
export const remittanceInformation = createAccountAsyncThunk('post','remittanceInformation', 'application/json', 'report/remittance_info/')
export const localFileExtraction = createAccountAsyncThunk('post', 'localFileExtraction', 'multipart/form-data', 'local_file_extraction_service/local_file_extraction/');
export const NotificationsList = createAccountAsyncThunk('post', 'NotificationsList', 'application/json', 'notification/notification_list/');
export const FcmTokenUpdate = createAccountAsyncThunk('put', 'FcmTokenUpdate', 'application/json', 'notification/fcm_token_update/');
export const NotificationsReadByUser = createAccountAsyncThunk('put', 'NotificationsReadByUser', 'application/json', 'notification/notification_update/');
export const NotificationsDeleteByUser = createAccountAsyncThunk('delete', 'NotificationsDeleteByUser', 'application/json', 'notification/notification_delete/');
export const reportQueryDelete = createAccountAsyncThunk('delete','reportQueryDelete', 'application/json','report/report_query_delete/')
export const migrateRelationalDb = createAccountAsyncThunk('post','migrateRelationalDb', 'application/json','database_migration/relational_database/')
export const migrateNonRelationalDb = createAccountAsyncThunk('post','migrateNonRelationalDb', 'application/json','database_migration/non_relational_database/')
export const migratePineconeDb = createAccountAsyncThunk('post','migratePineconeDb', 'application/json','database_migration/vector_database/')
export const mappingList = createAccountAsyncThunk('post','mappingList', 'application/json', 'mapping/table_mapping_list/')
export const updateMapping = createAccountAsyncThunk('put','updateMapping', 'application/json', 'mapping/mapping_status_update/')


