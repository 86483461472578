import './App.css';
import React, { lazy, Suspense } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from "./redux/store"
import Notifications from './components/Notifications/Notifications';
function App() {

  const AppRouter = lazy(() => import('../src/routes/routeindex'))
  return (
    <Provider store={store}>
       <Notifications/>
      <Router>
        <Suspense fallback={<div>loading...</div>}>
          <AppRouter />          
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
