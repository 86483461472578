import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../constants/baseurl";
import { apiCall } from "../../api/index";
import { showLoading } from "../slice/loadingslice"



const createAccountAsyncThunk = (method, type, url) => createAsyncThunk(type, async (payload, thunkAPI) => {
    const { data } = payload;
    const { dispatch } = thunkAPI;
    console.log("data:::", data)
    const apiRequest = {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}${url}`,

        data,
    };

    try {
        dispatch(showLoading.actions.toggleLoading(true));
        const res = await apiCall(apiRequest, dispatch);
        const response = res.data;

        dispatch(showLoading.actions.toggleLoading(false));

        if (response.statusFlag === true) {
            console.log(`login action ------ ${type}`, response);

            // const token = response.data.login_info[0]['token'];
            // console.log('tokenaction',token)
            // localStorage.setItem("token", token);
            return response;
        } else {
            console.error(`account action - ${type}`, response);

            // Check if there is an error code in the response
            const errorCode = response.message;

            // You can throw a custom error with the error code
            throw new Error(errorCode);
        }
    } catch (error) {
        dispatch(showLoading.actions.toggleLoading(false));
        throw error;
    }
});


export const signupUser = createAccountAsyncThunk('post', 'signupUser', 'Login_service/validate_email/');
export const verifyOtp = createAccountAsyncThunk('post', 'verifyOtp', 'Login_service/verify_otp/');
export const userLogin = createAccountAsyncThunk('post', 'userLogin', 'Login_service/login/');
export const accountCreation = createAccountAsyncThunk('post', 'accountCreation', 'Login_service/user_signup/');
export const UserForgotPassword = createAccountAsyncThunk('post', 'UserForgotPassword', 'Login_service/forgot_password/');
export const UserResetPassword = createAccountAsyncThunk('post', 'UserResetPassword', 'Login_service/reset_password/')

