import { createSlice } from "@reduxjs/toolkit";
import { UserForgotPassword, UserResetPassword, accountCreation, signupUser, userLogin, verifyOtp } from "../actions/docsaction";


const signupUserSlice = createSlice({
    name: 'signupUser',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(signupUser.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(signupUser.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(signupUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const verifyOtpSlice = createSlice({
    name: 'verifyOtp',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(verifyOtp.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(verifyOtp.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const userLoginSlice = createSlice({
    name: 'userLogin',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(userLogin.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(userLogin.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(userLogin.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const accountCreationSlice = createSlice({
    name: 'accountCreation',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(accountCreation.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(accountCreation.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(accountCreation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const UserForgotPasswordSlice = createSlice({
    name: 'UserForgotPassword',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(UserForgotPassword.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(UserForgotPassword.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(UserForgotPassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});

const UserResetPasswordSlice = createSlice({
    name: 'UserResetPassword',
    initialState: {
        loading: false,
        data: null,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(UserResetPassword.pending, (state) => {
                console.log("loaadingslice1")
                state.loading = true;
                state.error = null;
            })
            .addCase(UserResetPassword.fulfilled, (state, action) => {
                console.log("loaading")
                state.loading = false;
                state.data = action.payload;
                // Call the success callback if provided
                if (typeof action.meta.arg.successFn === 'function') {
                    action.meta.arg.successFn(action.payload);
                    console.log("action page", action.payload)
                }
            })
            .addCase(UserResetPassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                // Call the failure callback if provided
                if (typeof action.meta.arg.failureFn === 'function') {
                    action.meta.arg.failureFn(action.error.message);
                    console.log("action:::", action.error.message)
                }
            });
    }
});
// export default signupUserSlice.reducer

export const reducers = {
    signupUser: signupUserSlice.reducer,
    verifyOtp: verifyOtpSlice.reducer,
    userLogin: userLoginSlice.reducer,
    accountCreation: accountCreationSlice.reducer,
    UserForgotPassword: UserForgotPasswordSlice.reducer,
    UserResetPassword: UserResetPasswordSlice.reducer

};